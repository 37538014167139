import React from "react";
import {Link} from "gatsby";

import AppLayout from "../views/containers/appLayout";
import AppLayoutContainer from "../views/containers/AppLayoutContainer";
import Layout from "../views/components/layout";
import SEO from "../views/components/seo";
import Title from "../views/components/title";

import NotFoundIllustration from "../images/icon-404.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <AppLayout>
          <Title border={true}>404 error</Title>
          <AppLayoutContainer>
            <img
              alt="Ghost getting abducted by aliens"
              className="block mx-auto w-20 mb-6"
              src={NotFoundIllustration}
            />
            <p className="text-center mb-4 font-regular">Sorry, page not found</p>
            <p className="text-center mb-4 font-regular">Please <Link to={"/"} className="font-bold">return home</Link> or <a href={`mailto:info@etho.co.uk`} className="font-bold">contact us</a></p>
          </AppLayoutContainer>
      </AppLayout>
    </Layout>
  );
}

export default NotFoundPage;
