import { getTokens } from "./auth";
var Promise = require('promise');
const axios = require('axios').default;

export const asyncGetOptions = (inputValue, endpoint) =>
  new Promise((resolve, reject) => {
    let args = {
      method: 'get',
      url: process.env.ETHO_API + '/' + endpoint,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }
    if (inputValue && inputValue.length > 0) {
      args.params = { query: '{"name":{"$options": "i", "$regex":"' + inputValue + '"}}' }
    }
    axios(args)
      .then((res) => {
        const data = res.data.map((el) => { return { value: el._id, label: el.name } });
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  });

  // this is useful, but may not work as intended as admins needs to hit the members endpoint
  export function updateSelectState() {
    const selects = ["admins", "organisations"]
    selects.forEach(select => {
      let currentValues = [];
      const selectedIDs = this.state[select];
      asyncGetOptions([], select)
      .then((els) => {
        els.forEach(el => {
          selectedIDs.forEach(ID => {
            if(el.value === ID) {
              currentValues.push({ label: el.label, value: ID })
            }
          })
        })
        this.setState({ [select]: currentValues }, () => console.log(this.state));
      })
  })
}

export function getFirstName(name) {
  const fullName = name.split(" ");
  return fullName[0];  
}

export function getUrlParam(name, url) {
  var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url);
  if (results == null){
     return null;
  }
  else {
     return decodeURI(results[1]) || 0;
  }
}