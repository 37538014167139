import React, {useEffect} from "react";
import {navigate} from "gatsby";
import { getTokens, isAuthenticated} from "../utils/auth";
import Layout from "../views/components/layout";
import SEO from "../views/components/seo";

const axios = require('axios').default;

function IndexPage() {
  useEffect(() => {
    // if not logged in, redirect to login
    if(!isAuthenticated()) {
      console.log("user is not authenticated");
      // loginPage();
      navigate("/app/login");
    } else {
      // get todays date
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      if(dd<10) {dd='0'+dd;}
      if(mm<10) {mm='0'+mm;}
      today = mm+'-'+dd+'-'+yyyy;

      // get attendances today
      axios({
        method: 'get',
        url: `${process.env.ETHO_API}/me/attendances?filter[check_in_time][$gte]=${today}&sort[check_in_time]=-1&page[limit]=1`,
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((res) => {
          console.log(res.data);
          // if null or checkout time set -> redirect to check-in
          if(!res.data || res.data.length == 0 || res.data[0].check_out_time) {
            navigate("/app/attendance")
          } else {
            navigate("/app/attendance/checkout")
          }
        })
        .catch((err) => {
          console.log(err)
          throw err;
        })
    }
  });

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="text-center">
        <div className="leading-loose pt-8">
          Loading...
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
